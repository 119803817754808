import { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Error as KendoError } from '@progress/kendo-react-labels';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { DialogActionsBar, Dialog } from '@progress/kendo-react-dialogs';
import { Stepper, type StepperChangeEvent } from '@progress/kendo-react-layout';

import { BaseDropDownList } from '../../Inputs';
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';
import { parseJwt } from '../../helpers/decodeJWT/decodeJWT';
import ProjectActionButtons from '../../ProjectActionButtons/ProjectActionButtons';
import { returnProjectsData } from '../../helpers/project/returnProjectsData/returnProjectsData';
import { fetchPostJson as addSurvey, fetchGetJsonOrEmptyArray as getSurveysFromProject, rsAppUrl } from '../../../../services/services';
import type { ProjectDocument } from '../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';

export interface FormDataInterface {
  name: string;
  description: string;
  project: TODO;
  survey: TODO;
}

interface Props {
  survey: TODO;
  projectDocuments: ProjectDocument[] | null;
  projectId: string;
  token: string;
  handleClose: () => void;
}

export const AddSurveyFromProjectModal = ({ survey, projectDocuments, token, projectId, handleClose }: Props) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [stepperValue, setStepperValue] = useState(0);

  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [surveysLoading, setSurveysLoading] = useState(false);
  const [formData, setFormData] = useState<FormDataInterface>(
    survey
      ? { name: `Copy of ${survey.name}`, description: survey.description, project: { id: projectId }, survey }
      : { name: '', description: '', project: null, survey: null }
  );
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [DCVersion, setDCVersion] = useState(1)

  // For now, only show the DC version toggle for the two customers that have access to it (Walr Staging and Walr Production customers)
  const parsedToken = parseJwt(token);
  const customerId = parsedToken[`${rsAppUrl}/customerid`];
  const showDCVersionToggle = (customerId === '024f7442-48b8-4b31-9b07-92421f0e602f' || customerId === '3ef11f3f-290d-4a64-925c-34ae02f863ce') ? true : false;

  const stepperItems = useMemo(() => {
    return [{ label: 'Select survey' }, { label: 'Survey general settings', disabled: !formData.survey }];
  }, [formData.survey]);

  useEffect(() => {
    async function getData() {
      try {
        setProjectsLoading(true);
        const projects = await returnProjectsData(token, dispatch);
        !projects.error && setProjects(projects.originalData);
      } catch (e) {
      } finally {
        setProjectsLoading(false);
      }
    }
    if (token) {
      getData();
    }
  }, [token, dispatch]);

  const handleChange = (e: StepperChangeEvent) => setStepperValue(e.value);

  const onChangeHandler = async ({ target, value }: TODO) => {
    setError(null);
    const { name } = target;
    if (name === 'project') {
      setFormData({ ...formData, [name]: value });
      try {
        setSurveysLoading(true);
        const surveys = await getSurveysFromProject(`su/projects/${value.id}/surveys`, token);

        // @ts-ignore
        !surveys.error && setSurveys(surveys);
      } catch (e) {
      } finally {
        setSurveysLoading(false);
      }
    } else if (name === 'survey') {
      setFormData({ ...formData, [name]: value, name: `Copy of ${value.name}`, description: value.description });
    } else {
      // @ts-ignore
      setFormData({ ...formData, [name]: value });
    }
  };

  const onSubmitRequest = () => {
    //@ts-ignore
    formRef.current.requestSubmit();
  };

  const onSubmitHandler = async (e: TODO) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true)
    try {
      const response = await addSurvey(`su/projects/${projectId}/surveys/from-survey/${formData.project.id}/${formData.survey.id}`, token, {
        name: formData.name,
        description: formData.description,
        dataCollectionVersion: DCVersion
      });
      if (response.statusCode && response.statusCode > 300) {
        setError(response.message ? response.message : response.error);
        setIsLoading(false)
      } else {
        setIsLoading(false)
        handleClose();
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Copying survey from existing project started' } })
      }
    } catch (err) {
      setIsLoading(false)
    }
  };

  if (survey) {
    return (
      <Dialog title={`Copy survey "${survey.name}"`} onClose={handleClose} width={650} height={500}>
        <form ref={formRef} onSubmit={onSubmitHandler}>
          <div className="p-4">
            <div className="row">
              <div className="col-md-12 d-flex flex-column mb-2">
                <Input
                  name="name"
                  className="w-100 mb-2"
                  label="Name"
                  value={formData.name}
                  onChange={onChangeHandler}
                  required
                  valid={!!(formData.name && !projectDocuments?.some((document) => document.name.toLowerCase() === formData.name.toLowerCase()))}
                  validationMessage={'Please enter a valid value'}
                />
                {!!(formData.name && projectDocuments?.some((document) => document.name.toLowerCase() === formData.name.toLowerCase())) && (
                  <KendoError className={'ml-2'}>There is existing document with same name</KendoError>
                )}
              </div>
              <div className="col-md-12 d-flex flex-column">
                <Input className="w-100" name="description" label="Description" value={formData.description} onChange={onChangeHandler} />
              </div>
            </div>
          </div>
        </form>
        {
          error &&
          <div className="mx-4">
            <ErrorMessage type="alert" errorMessage={error} onHide={() => setError(null)} />
          </div>
        }
        <DialogActionsBar>
          <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>
            Cancel
          </button>
          <button type='button' className="k-button btn btn-primary" onClick={onSubmitRequest}>
            Copy
          </button>
        </DialogActionsBar>
      </Dialog>
    );
  }

  return (
    <Dialog title="Copy survey from existing project" onClose={handleClose} width={650} height={500}>
      <form ref={formRef} onSubmit={onSubmitHandler}>
        <div className="p-4">
          <div className="pb-3">
            <Stepper value={stepperValue} onChange={handleChange} items={stepperItems} />
          </div>
          {stepperValue === 0 ? (
            <div className="row mb-2">
              <div className="col-md-12 d-flex flex-column mb-2">
                <BaseDropDownList
                  name="project"
                  label="Project"
                  data={projects}
                  value={formData.project}
                  onChange={onChangeHandler}
                  textField="name"
                  dataItemKey="id"
                  loading={projectsLoading}
                  className="w-100"
                  required
                  validityStyles={false}
                />
                {formData.project && !surveysLoading && !surveys.length && (
                  <KendoError className={'ml-2'}>Selected project does not contain TODO surveys!</KendoError>
                )}
              </div>
              <div className="col-md-12 d-flex flex-column mb-2">
                <BaseDropDownList
                  name={'survey'}
                  label="Survey"
                  data={surveys}
                  value={formData.survey}
                  onChange={onChangeHandler}
                  textField="name"
                  dataItemKey="id"
                  loading={surveysLoading}
                  className="w-100"
                  required
                  validityStyles={false}
                />
              </div>
            </div>
          ) : (
            <div className="row mb-2">
              <div className="col-md-12 d-flex flex-column mb-2">
                <Input
                  name="name"
                  className="w-100"
                  label="Name"
                  value={formData.name}
                  onChange={onChangeHandler}
                  required
                  valid={!!(formData.name && !projectDocuments?.some((document) => document.name.toLowerCase() === formData.name.toLowerCase()))}
                  validationMessage={'Please enter a valid value'}
                />
                {!!(formData.name && projectDocuments?.some((document) => document.name.toLowerCase() === formData.name.toLowerCase())) && (
                  <KendoError className={'ml-2'}>There is existing document with same name</KendoError>
                )}
              </div>
              <div className="col-md-12 d-flex flex-column mb-2">
                <Input className="w-100" name="description" label="Description" value={formData.description} onChange={onChangeHandler} />
              </div>
              {showDCVersionToggle && (
                <div className=" col-md-12 d-flex flex-column mb-2">
                  <div className={"d-flex justify-content-between align-items-center form-control mt-2 py-5"}>
                    <p className="mr-3 mb-0 text-primary">Use Data Collection V2</p>
                    <Switch checked={DCVersion === 2} onChange={(e) => setDCVersion(e.value ? 2 : 1)} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
      {error && <ErrorMessage type="alert" errorMessage={error} onHide={() => setError(null)} />}
      <DialogActionsBar>
        <ProjectActionButtons
          formRef={formRef}
          submitAction={'Copy'}
          onHide={handleClose}
          stepperValue={stepperValue}
          steps={stepperItems.length}
          isLoading={isLoading}
          handleChange={handleChange}
          onSubmitHandler={onSubmitRequest}
        />
      </DialogActionsBar>
    </Dialog>
  );
};
